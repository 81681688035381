@media (prefers-color-scheme: light) {
  :root {
    --background: #ffffff;
    --card-background: #ffffff;
    --text-primary: #555555;
    --text-secondary: #9d9ea2;
    --text-silent: #9d9ea2;
    --card-border: #c8cace;
  }
}
.google-input {
  color: var(--text-secondary);
  position: relative;
  height: 42px;
  width: fit-content;
}
.google-input label {
  position: absolute;
  left: 12px;
  top: 13px;
  width: max-content;
  transition: all 0.2s ease-in-out;
  /* background: var(--card-background); */
  color: var(--text-secondary);
  /* padding: 5px 2px; */
  border-radius: 4px;
  pointer-events: none;
  font-weight: bolder;
}

label {
  height: 12px;
  z-index: 10;
}
.google-input input {
  position: relative;
  top: 4px;
  left: 0;
  width: 257px;
  height: 42px;
  border-radius: 4px;
  background: var(--card-background);
  border: 1px solid var(--card-border);
  color: var(--text-primary);
  font-size: 0.875rem;
}

/* 툴팁 아이콘 스타일 */
.google-input .tooltip-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: help; /* 마우스 커서를 도움말 아이콘으로 변경 */
}

.google-input input:focus {
  border: 1px solid #1f62b9;
  outline: 1px solid #1f62b9;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.google-input input:focus ~ label,
.google-input input:not(:placeholder-shown) ~ label {
  top: -4px;
  font-size: 11px;
  background-color: white;
}
.google-input input:focus ~ label {
  color: #1f62b9;
  font-weight: bolder;
}
.tooltip-icon {
  z-index: 100;

  width: 15px;
  height: 15px;
  margin-top: 3px;
  margin-right: 2px;
  background-repeat: no-repeat;
  background-image: url("/public/images/info_icon.svg");
}
.input-error-text {
  color: #d20707;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 0px;
}

.form-container p {
  @media screen and (max-width: 600px) {
    font-size: 0.6rem;
  }
}

.template-input {
  padding-left: 15px;
  border-width: 100px;
}

/* input 테두리 제거 */
input {
  border: none;
  outline: none;
}

@keyframes diffuse {
  0%,
  100% {
    filter: drop-shadow(0 0 10px rgba(210, 240, 255, 0.2));
  }
  50% {
    filter: none;
  }
}
